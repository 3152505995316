.tracking-page {
    max-width: 1500px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

h1 {
    text-align: center;
    color: #3498db;
    margin-bottom: 20px;
}

.tracking-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tracking-table th,
.tracking-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.tracking-table th {
    background-color: #f4f4f4;
    color: #34495e;
}

.tracking-table td {
    font-size: 16px;
}

.status {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.status.arrived {
    background-color: #2ecc71;
}

.status.shipped {
    background-color: #e67e22;
}

.status.packaging {
    background-color: #3498db;
}

.status.order_placed {
    background-color: #9b59b6;
}

.status.shipping {
    background-color: #e74c3c;
}

.tracking-table tr:hover {
    background-color: #f1f1f1;
}