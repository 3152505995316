/* Sidebar styles */
.sidebar {
    height: 100vh;
    width: 250px;
    background-color: #f5f7fa;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.sidebar-header .logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100px;
}

.sidebar-header h2 {
    color: #34495e;
    font-size: 18px;
    font-weight: bold;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sidebar-menu li {
    margin: 15px 0;
}

.sidebar-menu li a {
    display: flex;
    align-items: center;
    color: #34495e;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.sidebar-menu li a i {
    margin-right: 15px;
    font-size: 18px;
}

.sidebar-menu li a:hover,
.sidebar-menu li a.active {
    background-color: #e0f3ff;
    color: #3498db;
}

.sidebar-menu li a.active i,
.sidebar-menu li a:hover i {
    color: #3498db;
}

.sidebar-menu li a i {
    color: #95a5a6;
}