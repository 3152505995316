* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background: linear-gradient(135deg, #856cc4 0%, #384268 100%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-logo {
    width: 200px; /* Ajuster la taille selon tes besoins */
    margin: 20px;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.login-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.login-box h1 {
    margin-bottom: 20px;
    font-size: 24px;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-group input {
    width: 300px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background: linear-gradient(135deg, #856cc4 0%, #384268 100%);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.login-button:hover {
    background: linear-gradient(135deg, #856cc4 0%, #856cc4 100%);
}

.login-links {
    font-size: 12px;
    color: #888;
}

.login-links a {
    color: #7f00ff;
    text-decoration: none;
}

.login-links a:hover {
    text-decoration: underline;
}

.login-links p {
    margin-top: 10px;
}