* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.registration-page {
    width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #3498db;
}



.input-group label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    color: #34495e;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.submit-btn {
    width: 45%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    align-self: center;
    margin-top: 10px;
}

.submit-btn:hover {
    background-color: #2980b9;
}

.form-flex {
    display: flex;
    align-items: center;
    column-gap: 50px;
    padding: 15px;
}